<template>
    <div class="container">
        <div class="col-md-12 mb-3">
            <div class="card p-3">
                <div v-for="item in kategori" :key="item.inv_id" class="card-header bg-transparent mb-3 h9 pt-0 fw-bold">
                    Detail Produk {{ item.inv_name }}

                </div>
                <table class="table table-hover table-responsive" id="ratio" style="cursor:pointer">
                    <thead>
                        <tr class="table-head">
                            <th class="">Nama Item</th>
                            <th class="">Qty</th>
                            <th class="">Rate</th>
                            <th class="">Date</th>
                        </tr>
                    </thead>
                </table>
                <div v-if="!kategori.length">Tidak ada item yang di tambahkan</div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";


// import "jquery/dist/jquery.min.js";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'D-Customer',
    components: {
        // PieChart
    },

    data() {
        return {
            screenHeight : 0,
            recsratiocust : [],
            recsCust : [],
            kategori : [],
            invId : null,
        }
    },
    created() {
    this.invId = this.$route.params.inv_id;
    console.log(this.invId);
    // Load data atau lakukan operasi lainnya berdasarkan this.invId
  },
props: ['inv_id'],
    watch: {
        inv_id: {
      immediate: true,
      handler(newInvId) {
        this.LoadData(newInvId);
      }
    }
    },
    methods : {
        LoadData(invId){
            let self = this;
            document.getElementById('idLoading').style.display='block';
            // const invId = self.$route.params.inv_id;

            axios.post("invent/ApiInv.php",{
                pfunction : "showdtl",
                inv_id: invId
            }).then(function (response) {
                document.getElementById('idLoading').style.display='none';
                self.recsratiocust = response.data.rec;
                $('#ratio').DataTable({
                    destroy: true,
                    responsive: true,
                    scrollX: false,
                    deferRender: true,
                    autoWidth: false,
                    // responsive: true,
                    data : self.recsratiocust,
                    columns: [
                        {"data": "pem_deskripsi"},
                        {"data": "pem_qty"},
                        {"data": "pem_rate",render: $.fn.dataTable.render.number( '.', ',', 2)},
                        {"data": "pem_date"}
                    ],
                    columnDefs: [
                    ],
                    order: [[ 1, "desc" ]],
                    dom: "<'d-flex dlex-row flex-nowrap mb-3 align-items-center p-0'<'h9 col-md-6'l><'h9 col-md-6'f>>" +
                    "<'col-md-6'B>" + "<'row'<'col-md-12 h9 mb-3'tr>>" + "<'row'<'col-12 col-md-5 h9'i><'col-12 col-md-7'p>>",
                    buttons: [
                        {
                            extend: 'csvHtml5', text: 'Export',
                            className: 'btn btn-primary',
                        }
                    ],
                    // buttons: ['csv']
                });
            });
            let param = {
                inv_id : invId,
                pfunction : 'showall',
            }
            axios.post("invent/ApiInv.php",param).then(function (response) {
                self.kategori = response.data.rec;
            });
        },
        getPercen(a, b){
            let percentage = (a * 100 / b).toFixed(2) + '%';
            return percentage;
        },
        setInit() {
            this.LoadData();
        },
        getColor(){
            let code = '#' + Math.floor(Math.random() * 16777215).toString(16);
            return code;
        },
        toRph(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    mounted(){
        // this.LoadData()
        this.screenHeight = window.innerHeight;
        // this.setInit();
    }
};
</script>